import React, { useState, useContext } from 'react';
import { useMsal } from '@azure/msal-react';
import { useHistory, useLocation } from 'react-router-dom';
import { Link, NavLink } from 'react-router-dom';
import { request } from '../../api';
import {
  AlertModal,
  HospitalSwitchModal,
  modalPresets,
} from '../CustomComponents/Modals';
import {
  OfflineModeContext,
  UserDataContext,
} from '../../Context';

const offlineToggleText =
  'Switching to offline mode will disable functionality throughout the app.';
const onlineToggleText =
  'Switching to online mode will automatically delete all cases entered during this session.';

const SignOutButton = () => {
  const { instance } = useMsal();
  const handleLogout = () => {
    if (typeof window !== "undefined") {
      localStorage.setItem('paperworkModal', 'false');
    }
    instance.logoutRedirect({
      postLogoutRedirectUri: '/',
    });
  };
  return (
    <button
      className="button button--menu-dropdown "
      onClick={() => handleLogout()}
    >
      Sign out
    </button>
  );
};

const Header = ({ unsavedChanges, displayVersionNumber }) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const { userData, setUserData } = useContext(UserDataContext);
  const { offlineMode } = useContext(OfflineModeContext);

  const [modal, setModal] = useState(modalPresets().default);
  const [selection, setSelection] = useState('0');
  const [hospitalSwitchModal, setHospitalSwitchModal] = useState(false);
  const [hospitalSwitchTo, setHospitalSwitchTo] = useState('');
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [showOowMenu, setShowOowMenu] = useState(false);
  const [showHelpMenu, setShowHelpMenu] = useState(false);
  const [oowPassword, setoowPassword] = useState('');
  const [collapseHeader, setCollapseHeader] = useState(false);

  const { guid, hospitalData, hospital, oowValid } = userData;
  const {
    hasUnreadMessages,
    lastSubmittedDate,
    name,
    quarterlyErvsEntered,
    numberOfSubmittedToday,
  } = hospital;

  const collapse = () => setCollapseHeader(!collapseHeader);

  const toggleOfflineMode = () => {
    toggleMenu();
    if (offlineMode) {
      handleModal(modalPresets(onlineToggleText).warning);
    } else {
      handleModal(modalPresets(offlineToggleText).warning);
    }
  };

  const handleModal = (modalPreset) => setModal(modalPreset);

  const onChange = (e) => {
    const { value } = e.target;
    if (unsavedChanges === true) {
      setHospitalSwitchTo(value);
      setShowUserMenu(!showUserMenu);
      setHospitalSwitchModal(true);
    } else {
      changeHospital(value);
    }
  };
  const changeHospital = (newHospital) => {
    setUserData((prevState) => ({
      ...prevState,
      hospital: hospitalData[newHospital],
    }));
    localStorage.setItem(
      'selectedHospital',
      JSON.stringify(hospitalData[newHospital])
    );
    setSelection(newHospital);
    history.push(`/`);
    setShowUserMenu(false);
    setHospitalSwitchModal(false);
  };

  const toggleMenu = () => setShowUserMenu(!showUserMenu);
  const toggleOOW = () => setShowOowMenu(!showOowMenu);
  const toggleHelp = () => setShowHelpMenu(!showHelpMenu);

  const submitoowPassword = async () => {
    setShowOowMenu(false);
    try {
      setModal({
        open: true,
        error: false,
        loading: true,
        message: 'Submitting OOW Password...',
      });
      const password = await request({
        def: 'oowPassword',
        queryString: oowPassword,
      });

      if (password.isValid) {
        setModal({
          open: true,
          error: false,
          loading: false,
          message: 'Success',
        });
        setUserData((prevState) => ({ ...prevState, oowValid: true }));
      } else {
        setModal({
          open: true,
          error: true,
          loading: false,
          message: 'Incorrect OOW Password',
        });
        setUserData((prevState) => ({ ...prevState, oowValid: false }));
      }
    } catch (err) {
      setModal({
        open: true,
        error: true,
        loading: false,
        message: `Failed to submit OOW password. Please try again. ${err}`,
      });
    }
  };

  return (
    <>
      <header
        role="banner"
        className={'header ' + (collapseHeader ? 'header--collapsed' : '')}
        id="header"
      >
        <a href="#main" className="skip-nav">
          Skip to main content
        </a>
        <div className="banner-wrapper">
          <div className="banner-wrapper__logo">
            <Link className="logo-neiss" to="/">
              <span className="ut-only-sr">CPSC NEISS Logo</span>
            </Link>
          </div>
          <div className="banner-wrapper__logo-text logo-text-wrapper logo-text">
            {collapseHeader ? (
              <span>
                <span className="logo-text logo-text--usa">
                  U.S. CPSC
                </span>
              </span>
            ) : (
              <span>
                <span className="logo-text logo-text--usa">United States</span>
                <br />
                <span className="logo-text logo-text--agency ut-nowrap">
                  Consumer Product Safety Commission
                </span>
                <br />
                <span className="logo-text logo-text--neiss">
                  National Electronic Injury Surveillance System
                </span>
              </span>
            )}
          </div>

          <div className="banner-wrapper__hospital-submitted">
            <span className="header-dashboard-item">
              <span className="ut-font-regular">HOSPITAL:</span>{' '}
              <span className='ut-font-bold ut-color-yellow'>{name}</span>
            </span>
            <span className="header-dashboard-item header-dashboard-item__submitted ut-nowrap">
              <span className="ut-font-regular">Type:</span>{' '}
              {hospitalData && hospitalData[selection].hospitalTypeCurrent}
            </span>
            <span className="header-dashboard-item header-dashboard-item__submitted ut-nowrap">
              <span className="ut-font-regular">Cases Submitted Today:</span> {hospital && numberOfSubmittedToday}
            </span>
            <span className="header-dashboard-item header-dashboard-item__submitted ut-nowrap">
              <span className="ut-font-regular">Last Submitted:</span> {hospital && lastSubmittedDate}
            </span>
          </div>

          <div className="banner-wrapper__user header-dashboard-column">
            <span className="header-dashboard-item ut-relative">
              {/* ******** User/Profile button & Menu ******** */}
              <button
                className="button button--header-drop-down ut-nowrap"
                onClick={toggleMenu}
              >
                <span className="button--header-drop-down-text">
                  {userData && userData.name}
                </span>
                &nbsp;&nbsp;
                <span className="fa-icon header-dashboard-item__icon">
                  &#xf078;
                </span>
              </button>
              <div
                className={
                  'menu-dropdown menu-dropdown--user ' +
                  (showUserMenu ? '' : 'ut-display-none')
                }
                aria-live="polite"
              >
                <ul className="menu-dropdown__link-list">
                  {guid && !offlineMode && (
                    <li className="menu-dropdown__link-list-item">
                      <a
                        className="button button--menu-dropdown"
                        href="https://account.live.com/resetpassword.aspx"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Change Password
                      </a>
                    </li>
                  )}
                  {!offlineMode && (
                    <>
                      <li className="menu-dropdown__link-list-item">
                        <button
                          className="button button--menu-dropdown"
                          disabled
                        >
                          Profile &amp; Settings
                        </button>
                      </li>
                      <li className="menu-dropdown__link-list-item">
                        <SignOutButton />
                      </li>
                    </>
                  )}
                  {/* <li className="menu-dropdown__link-list-item">
                    <button
                      className="button button--menu-dropdown"
                      onClick={toggleOfflineMode}
                    >
                      Switch to {offlineMode ? 'Online Mode' : 'Offline Mode'}
                    </button>
                  </li> */}
                </ul>
                <hr className="menu-dropdown__divider" />
                <div className="menu-dropdown__link-list-header ut-align-center ut-padding-top-0 ut-margin-bottom-half-em">
                  Set Hospital
                </div>
                <ul className="menu-dropdown__link-list ut-margin-top-0">
                  {userData &&
                    hospitalData.map((hospital, i) => {
                      if (offlineMode && name !== hospital.name) return null;
                      return (
                        <li
                          className="menu-dropdown__link-list-item"
                          key={hospital.groupId}
                        >
                          <button
                            className={`button button--menu-dropdown button--menu-dropdown-hospital ${
                              hospital.quarterlyErvsEntered
                                ? ''
                                : 'button--needs-ervs'
                            }`}
                            value={i}
                            onClick={(e) => onChange(e)}
                            disabled={hospital.name === name ? true : false}
                          >
                            <span className="ut-only-sr">Set hospital to:</span>
                            {hospital.name}
                          </button>
                        </li>
                      );
                    })}
                </ul>
                <div
                  className="menu-dropdown__backdrop"
                  onClick={toggleMenu}
                ></div>
              </div>
            </span>
          </div>
          {/* ******** password button & Menu ******** */}
          {!offlineMode ? (
            <div className="banner-wrapper__oow ut-relative">
              <button className="button button--circle" onClick={toggleOOW}>
                <span className="ut-only-sr">Enter an OOW Password</span>
                {userData && oowValid ? (
                  <span className="fa-icon">&#xf13e;</span>
                ) : (
                  <span className="fa-icon">&#xf023;</span>
                )}
              </button>
              <div
                className={
                  'menu-dropdown ' + (showOowMenu ? '' : 'ut-display-none')
                }
                aria-live="polite"
              >
                <p className="menu-dropdown__paragraph">
                  Enter an <b>OOW Password</b> (Out-of-Window password supplied
                  by your representative) to update out-of-window cases.
                </p>
                <p className="menu-dropdown__paragraph">
                  OOW Passwords last for an entire day or your entire session,
                  whichever comes first.
                </p>
                <hr className="menu-dropdown__divider menu-dropdown__divider--short" />
                <div className="ut-align-center">
                  <label className="ut-align-center menu-dropdown__input">
                    <span className="label menu-dropdown__input-label">
                      Enter OOW Password
                    </span>
                    <input
                      type="password"
                      className="input menu-dropdown__input-element"
                      onChange={(e) =>
                        setoowPassword(e.target.value.toUpperCase())
                      }
                    />
                  </label>
                  <button
                    type="button"
                    className="button button--primary"
                    onClick={submitoowPassword}
                  >
                    Submit Password
                  </button>
                </div>
                <div
                  className="menu-dropdown__backdrop"
                  onClick={toggleOOW}
                ></div>
              </div>
            </div>
          ) : null}
          {/* ******** Help button & Menu ******** */}
          <div className="banner-wrapper__help ut-relative">
            <button
              className="button button--circle button-icon"
              onClick={toggleHelp}
            >
              <span className="ut-only-sr">View Help Menu</span>
              <span className="fa-icon">&#xf128;</span>
            </button>
            <div
              className={
                'menu-dropdown menu-dropdown--help ' +
                (showHelpMenu ? '' : 'ut-display-none')
              }
              aria-live="polite"
            >
              <div className="menu-dropdown__link-list-header">
                WEB NEISS User Guide
              </div>
              <ul className="menu-dropdown__link-list">
                <li className="menu-dropdown__link-list-item">
                  <a
                    href="/pdfs/WebNEISSUserGuide.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="menu-dropdown__link-list-link menu-dropdown__link-list-link--short"
                  >
                    <span className="fa-icon ut-padding-right-half-em">
                      &#xf1C1;
                    </span>
                    User Guide
                  </a>
                </li>
              </ul>
              {hospital.hospitalTypeCurrent !== 'NEISS-AIP' && (
                <>
                  <div className="menu-dropdown__link-list-header">
                    NEISS Coding Manuals
                  </div>
                  <ul className="menu-dropdown__link-list">
                    <li className="menu-dropdown__link-list-item">
                      <a
                        href="/pdfs/HelpNonTrauma.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="menu-dropdown__link-list-link menu-dropdown__link-list-link--short"
                      >
                        <span className="fa-icon ut-padding-right-half-em">
                          &#xf1C1;
                        </span>
                        Coding Manual
                      </a>
                    </li>
                    <li className="menu-dropdown__link-list-item">
                      <a
                        href="/pdfs/HelpNonTraumaProductCode.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="menu-dropdown__link-list-link menu-dropdown__link-list-link--short"
                      >
                        <span className="fa-icon ut-padding-right-half-em">
                          &#xf1C1;
                        </span>
                        Appendix A: Product and Activity Codes
                      </a>
                    </li>
                    <li className="menu-dropdown__link-list-item">
                      <a
                        href="/pdfs/HelpNonTraumaDiagnosis.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="menu-dropdown__link-list-link menu-dropdown__link-list-link--short"
                      >
                        <span className="fa-icon ut-padding-right-half-em">
                          &#xf1C1;
                        </span>
                        Appendix B: Diagnosis
                      </a>
                    </li>
                    <li className="menu-dropdown__link-list-item">
                      <a
                        href="/pdfs/HelpNonTraumaBodyPart.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="menu-dropdown__link-list-link menu-dropdown__link-list-link--short"
                      >
                        <span className="fa-icon ut-padding-right-half-em">
                          &#xf1C1;
                        </span>
                        Appendix C: Body Parts
                      </a>
                    </li>
                  </ul>
                </>
              )}
              {hospital.hospitalTypeCurrent === 'NEISS-AIP' && (
                <>
                  <div className="menu-dropdown__link-list-header">
                    NEISS AIP Coding Manuals
                  </div>
                  <ul className="menu-dropdown__link-list">
                    <li className="menu-dropdown__link-list-item">
                      <a
                        href="/pdfs/HelpTrauma.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="menu-dropdown__link-list-link menu-dropdown__link-list-link--short"
                      >
                        <span className="fa-icon ut-padding-right-half-em">
                          &#xf1C1;
                        </span>
                        Coding Manual
                      </a>
                    </li>
                    <li className="menu-dropdown__link-list-item">
                      <a
                        href="/pdfs/HelpTraumaProductCode.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="menu-dropdown__link-list-link menu-dropdown__link-list-link--short"
                      >
                        <span className="fa-icon ut-padding-right-half-em">
                          &#xf1C1;
                        </span>
                        Appendix A: Product and Activity Codes
                      </a>
                    </li>
                    <li className="menu-dropdown__link-list-item">
                      <a
                        href="/pdfs/HelpTraumaDiagnosis.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="menu-dropdown__link-list-link menu-dropdown__link-list-link--short"
                      >
                        <span className="fa-icon ut-padding-right-half-em">
                          &#xf1C1;
                        </span>
                        Appendix B: Diagnosis
                      </a>
                    </li>
                    <li className="menu-dropdown__link-list-item">
                      <a
                        href="/pdfs/HelpTraumaBodyPart.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="menu-dropdown__link-list-link menu-dropdown__link-list-link--short"
                      >
                        <span className="fa-icon ut-padding-right-half-em">
                          &#xf1C1;
                        </span>
                        Appendix C: Body Parts
                      </a>
                    </li>
                  </ul>
                  <div className="menu-dropdown__link-list-header">
                    Special Study Manuals
                  </div>
                  <ul className="menu-dropdown__link-list">
                    <li className="menu-dropdown__link-list-item">
                      <a
                        href="/pdfs/HelpStudyAdverseDrug.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="menu-dropdown__link-list-link menu-dropdown__link-list-link--short"
                      >
                        <span className="fa-icon ut-padding-right-half-em">
                          &#xf1C1;
                        </span>
                        Adverse Drug Events
                      </a>
                    </li>
                    <li className="menu-dropdown__link-list-item">
                      <a
                        href="/pdfs/HelpStudyAssaults.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="menu-dropdown__link-list-link menu-dropdown__link-list-link--short"
                      >
                        <span className="fa-icon ut-padding-right-half-em">
                          &#xf1C1;
                        </span>
                        Assaults
                      </a>
                    </li>
                    <li className="menu-dropdown__link-list-item">
                      <a
                        href="/pdfs/HelpStudyChildPoison.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="menu-dropdown__link-list-link menu-dropdown__link-list-link--short"
                      >
                        <span className="fa-icon ut-padding-right-half-em">
                          &#xf1C1;
                        </span>
                        Children’s Poisonings
                      </a>
                    </li>
                    <li className="menu-dropdown__link-list-item">
                      <a
                        href="/pdfs/HelpStudyFirearms.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="menu-dropdown__link-list-link menu-dropdown__link-list-link--short"
                      >
                        <span className="fa-icon ut-padding-right-half-em">
                          &#xf1C1;
                        </span>
                        Firearms
                      </a>
                    </li>
                    <li className="menu-dropdown__link-list-item">
                      <a
                        href="/pdfs/HelpStudyNHTSA.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="menu-dropdown__link-list-link menu-dropdown__link-list-link--short"
                      >
                        <span className="fa-icon ut-padding-right-half-em">
                          &#xf1C1;
                        </span>
                        NHTSA
                      </a>
                    </li>
                    <li className="menu-dropdown__link-list-item">
                      <a
                        href="/pdfs/HelpStudyNIOSH.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="menu-dropdown__link-list-link menu-dropdown__link-list-link--short"
                      >
                        <span className="fa-icon ut-padding-right-half-em">
                          &#xf1C1;
                        </span>
                        NIOSH
                      </a>
                    </li>
                    <li className="menu-dropdown__link-list-item">
                      <a
                        href="/pdfs/HelpStudySelfInflicted.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="menu-dropdown__link-list-link menu-dropdown__link-list-link--short"
                      >
                        <span className="fa-icon ut-padding-right-half-em">
                          &#xf1C1;
                        </span>
                        Self-Inflicted Injuries
                      </a>
                    </li>
                  </ul>
                </>
              )}
              <hr className="menu-dropdown__divider" />
              <div className="menu-dropdown__link-list-header menu-dropdown__link-list-header--shortcuts">
                Quick Select Short Cuts
              </div>
              <ul className="menu-dropdown__list menu-dropdown__list--shortcuts">
                <li>Diag1 = Shift+CTRL+1</li>
                <li>Body1 = Shift+CTRL+2</li>
                <li>Diag2 = Shift+CTRL+3</li>
                <li>Body2 = Shift+CTRL+4</li>
                <li>Prod1 = Shift+CTRL+5</li>
                <li>Prod2 = Shift+CTRL+6</li>
                <li>Prod3 = Shift+CTRL+7</li>
                <li>Save Case = Alt+CTRL+S</li>
                <li>Continue To Next Case = Alt+CTRL+C</li>
                <li>Next Tab = Alt+CTRL+=</li>
                <li>Previous Tab = Alt+CTRL+-</li>
              </ul>
              {displayVersionNumber && <>
                <hr className="menu-dropdown__divider" />
                <div className='menu-dropdown__link-list-header menu-dropdown__link-list-header--shortcuts'>
                  <span>1.6 ({displayVersionNumber}) </span>
                </div>
              </>
              }
              <div
                className="menu-dropdown__backdrop"
                onClick={toggleHelp}
              ></div>
            </div>
          </div>
          {/* ******** Header Collapse Button ******** */}
          <div className="banner-wrapper__collapse ut-relative">
            <button
              className="button button--circle button-icon"
              onClick={collapse}
              aria-live="polite"
            >
              {collapseHeader ? (
                <span>
                  <span className="ut-only-sr">Uncollapse Header</span>
                  <span className="fa-icon">&#xf0d9;</span>
                </span>
              ) : (
                <span>
                  <span className="ut-only-sr">Collapse Header</span>
                  <span className="fa-icon">&#xf0d7;</span>
                </span>
              )}
            </button>
          </div>
        </div>
        {/* ******** main page ******** */}
        <nav className="header-nav-wrapper" role="navigation">
          <ul
            className={`header-nav-list ${
              offlineMode ? 'header-nav-list--offline' : null
            }`}
          >
            <li className="header-nav-list__item">
              <NavLink
                to="/"
                aria-current="page"
                onClick={(e) => {
                  if (pathname === '/') e.preventDefault();
                }}
                exact
              >
                Dashboard
              </NavLink>
            </li>
            <li className="header-nav-list__item">
              <NavLink
                to="/newCase"
                aria-current="page"
                onClick={(e) => {
                  const regex = /^\/newCase/;
                  if (regex.test(pathname)) e.preventDefault();
                }}
              >
                Enter Case
              </NavLink>
            </li>
            {!offlineMode ? (
              <>
                <li className="header-nav-list__item header-nav-list__item-notice">
                  <NavLink
                    to="/messages"
                    aria-current="page"
                    onClick={(e) => {
                      if (pathname === '/messages') e.preventDefault();
                    }}
                    exact
                  >
                    {hasUnreadMessages ? (
                      <span className="header-nav-list__notice header-nav-list__notice">
                        <span className="fa-icon">&#xf12a;</span>
                      </span>
                    ) : null}
                    Messages
                  </NavLink>
                </li>
                <li className="header-nav-list__item">
                  <NavLink
                    to="/accounting"
                    aria-current="page"
                    exact
                    onClick={(e) => {
                      if (pathname === '/accouting') e.preventDefault();
                    }}
                  >
                    Accounting
                  </NavLink>
                </li>
                <li className="header-nav-list__item">
                  <NavLink
                    to="/ervs"
                    aria-current="page"
                    exact
                    onClick={(e) => {
                      if (pathname === '/ervs') e.preventDefault();
                    }}
                  >
                    {!quarterlyErvsEntered ? (
                      <span className="header-nav-list__notice fa-icon">
                        &#xf12a;
                      </span>
                    ) : null}
                    ERVs
                  </NavLink>
                </li>
              </>
            ) : null}
            {offlineMode ? (
              <span className="header-online-status">App in Offline Mode</span>
            ) : null}
          </ul>
        </nav>
      </header>
      <HospitalSwitchModal
        modal={hospitalSwitchModal}
        setModal={setHospitalSwitchModal}
        changeHospital={changeHospital}
        selectedHospital={hospitalSwitchTo}
      />
      <AlertModal modal={modal} closeModal={handleModal} />
    </>
  );
};

export default Header;
